import { Injectable, OnDestroy } from '@angular/core';
import { Subject ,  from } from 'rxjs';
import { ILayout, ICampaign, IContent, LayoutArea } from '../campaign.model';
import { filter } from 'rxjs/operators';
import { Guid } from '../../shared/models/guid';
import * as _ from 'lodash';
import { WidgetInstance } from '../widgets/widget';
import { SubSink } from 'SubSink/dist/subsink';

@Injectable({
    providedIn: 'root',
  })
export class CampaignBuilder implements OnDestroy {
    public campaign: ICampaign;
    public content: IContent;
    dayUpdate = 7 ;
    placeHolderImage = 'assets/images-demo/assets/image-placeholder.png';
    private subs = new SubSink();

    constructor() {
        this.initCampaign();
    }

    private campaignUpdatedSource = new Subject<ICampaign>();
    campaignUpdated$ = this.campaignUpdatedSource.asObservable();

    private orientationChangedSource = new Subject<string>();
    orientationChanged$ = this.orientationChangedSource.asObservable();

    contentSelectedSource = new Subject<IContent>();
    contentSelected$ = this.contentSelectedSource.asObservable();

    widgetInstanceSelectedSource = new Subject<WidgetInstance>();
    widgetInstanceSelectedSource$ = this.widgetInstanceSelectedSource.asObservable();

    initialLAyouts: ILayout[] = Object.assign(this.getLayouts());

    WidthHeightValue: LayoutArea = { Width: 1920, Height: 1080 };
    WidthHeightScale: LayoutArea = {Width: 1920, Height: 1080 } ;
    WidthHeightDefaultValueH: LayoutArea = {Width: 1920, Height: 1080};
    WidthHeightDefaultValueV: LayoutArea = {Width: 1080, Height: 1920};

  

    getLayouts(): ILayout[] {
        return  [
        // For Portrait
            { id: 1, layoutGroup: 'PROMOTION', mode: 'Portrait', cols: 1, rows: 1, imageUrl: 'assets/images-demo/layout/potrait-layout-1.svg',
                tiles: [{colspan: 1, ratio:  0.5625, minWidth: '1080px', minHeight: '1920px', rowspan: 1, col: 0, row: 0, title: 'Tile1', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'}]},
            { id: 2, layoutGroup: 'PROMOTION', mode: 'Portrait', cols: 1, rows: 2, imageUrl: 'assets/images-demo/layout/potrait-layout-2.svg',
                tiles: [{colspan: 1, ratio:  1.125, minWidth: '1080px', minHeight: '960px', rowspan: 1, col: 0, row: 0, title: 'Tile1', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'},
                        {colspan: 1, ratio:  1.125, minWidth: '1080px', minHeight: '960px', rowspan: 1, col: 0, row: 1, title: 'Tile2', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'}]},
            { id: 3, layoutGroup: 'PROMOTION', mode: 'Portrait', cols: 2, rows: 2, imageUrl: 'assets/images-demo/layout/potrait-layout-3.svg',
                tiles: [{colspan: 1, ratio: 0.5625, minWidth: '540px', minHeight: '960px', rowspan: 1, col: 0, row: 0, title: 'Tile1', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'},
                        {colspan: 1, ratio: 0.5625, minWidth: '540px', minHeight: '960px', rowspan: 1, col: 1, row: 0, title: 'Tile2', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'},
                        {colspan: 2, ratio:  1.125, minWidth: '1080px', minHeight: '960px', rowspan: 1, col: 0, row: 1, title: 'Tile3', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'}]},
            { id: 4, layoutGroup: 'PROMOTION', mode: 'Portrait', cols: 2,  rows: 2, imageUrl: 'assets/images-demo/layout/potrait-layout-4.svg',
                tiles: [{colspan: 2, ratio:  1.125, minWidth: '1080px', minHeight: '960px', rowspan: 1, col: 0, row: 0, title: 'Tile1', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'},
                        {colspan: 1, ratio: 0.5625, minWidth: '540px', minHeight: '960px', rowspan: 1, col: 0, row: 1, title: 'Tile2', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'},
                        {colspan: 1, ratio: 0.5625, minWidth: '540px', minHeight: '960px', rowspan: 1, col: 1, row: 1, title: 'Tile3', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'}]},
            { id: 5, layoutGroup: 'PROMOTION', mode: 'Portrait', cols: 1,  rows: 3, imageUrl: 'assets/images-demo/layout/potrait-layout-5.svg',
                        tiles: [{colspan: 1, ratio:  1.6875, minWidth: '1080px', minHeight: '640px', rowspan: 1, col: 0, row: 0, title: 'Tile1', adType: 'Image', url: this.placeHolderImage},
                                {colspan: 1, ratio:  1.6875, minWidth: '1080px', minHeight: '640px', rowspan: 1, col: 0, row: 1, title: 'Tile2', adType: 'Image', url: this.placeHolderImage},
                                {colspan: 1, ratio:  1.6875, minWidth: '1080px', minHeight: '640px', rowspan: 1, col: 0, row: 2, title: 'Tile3', adType: 'Image', url: this.placeHolderImage}]},
            // For LandScape
            { id: 11, layoutGroup: 'PROMOTION', mode: 'Landscape', cols: 1, rows: 1, imageUrl: 'assets/images-demo/layout/landscape-layout-1.svg',
                tiles: [{ratio: 1.778, minWidth: '1920px', minHeight: '1080px', colspan: 1, rowspan: 1, col: 0, row: 0, title: 'Tile1', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'}]},
            { id: 12, layoutGroup: 'PROMOTION', mode: 'Landscape', cols: 2, rows: 2, imageUrl: 'assets/images-demo/layout/landscape-layout-2.svg',
                tiles: [{ratio: 0.889, minWidth: '960px', minHeight: '1080px', colspan: 1, rowspan: 2, col: 0, row: 0, title: 'Tile1', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'},
                        {ratio: 0.889, minWidth: '960px', minHeight: '1080px', colspan: 1, rowspan: 2, col: 1, row: 0, title: 'Tile2', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'}]},
            { id: 13, layoutGroup: 'PROMOTION', mode: 'Landscape', cols: 2, rows: 2, imageUrl: 'assets/images-demo/layout/landscape-layout-3.svg',
                tiles: [{ratio: 1.778, minWidth: '960px', minHeight: '540px', colspan: 1, rowspan: 1, col: 0, row: 0, title: 'Tile1', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'},
                        {ratio: 0.889, minWidth: '960px', minHeight: '1080px', colspan: 1, rowspan: 2, col: 1, row: 0, title: 'Tile2', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'},
                        {ratio: 1.778, minWidth: '960px', minHeight: '540px', colspan: 1, rowspan: 1, col: 0, row: 1, title: 'Tile3', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'}]},
            {
                id: 14, layoutGroup: 'PROMOTION', mode: 'Landscape', cols: 2, rows: 2, imageUrl: 'assets/images-demo/layout/landscape-layout-4.svg',
                tiles: [{ratio: 0.889, minWidth: '960px', minHeight: '1080px', colspan: 1, rowspan: 2, col: 0, row: 0, title: 'Tile1', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'},
                        {ratio: 1.778, minWidth: '960px', minHeight: '540px', colspan: 1, rowspan: 1, col: 1, row: 0, title: 'Tile2', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'},
                        {ratio: 1.778, minWidth: '960px', minHeight: '540px', colspan: 1, rowspan: 1, col: 1, row: 1, title: 'Tile3', adType: 'Image', url: 'assets/images-demo/assets/image-placeholder.png'}
                    ]
            },
            { id: 15, layoutGroup: 'PROMOTION', mode: 'Landscape', cols: 3,  rows: 1, imageUrl: 'assets/images-demo/layout/landscape-layout-5.svg',
                    tiles: [{ratio: 0.5926, minWidth: '640px', minHeight: '1080px', colspan: 1, rowspan: 1, col: 0, row: 0, title: 'Tile1', adType: 'Image', url: this.placeHolderImage},
                            {ratio: 0.5926, minWidth: '640px', minHeight: '1080px', colspan: 1, rowspan: 1, col: 1, row: 0, title: 'Tile2', adType: 'Image', url: this.placeHolderImage},
                            {ratio: 0.5926, minWidth: '640px', minHeight: '1080px', colspan: 1, rowspan: 1, col: 2, row: 0, title: 'Tile3', adType: 'Image', url: this.placeHolderImage}]
            },
            { id: 16, layoutGroup: 'PROMOTION', mode: 'Landscape', cols: 4,  rows: 2, imageUrl: 'assets/images-demo/layout/landscape-layout-6.svg',
                    tiles: [
                            {ratio: 0.5926, minWidth: '640px', minHeight: '1080px', colspan: 1, rowspan: 2, col: 0, row: 0, title: 'Tile1', adType: 'Image', url: this.placeHolderImage},
                            // {colspan: 1, rowspan: 2, col: 0, row: 0, title: 'Tile4', adType: 'Image', url: this.placeHolderImage},
                            {ratio: 2.3704, minWidth: '1280px', minHeight: '540px', colspan: 3, rowspan: 1, col: 1, row: 0, title: 'Tile2', adType: 'Image', url: this.placeHolderImage},
                            {ratio: 2.3704, minWidth: '1280px', minHeight: '540px', colspan: 3, rowspan: 1, col: 1, row: 1, title: 'Tile3', adType: 'Image', url: this.placeHolderImage},
                        ]
            },
            { id: 17, layoutGroup: 'PROMOTION', mode: 'Landscape', cols: 4,  rows: 2, imageUrl: 'assets/images-demo/layout/landscape-layout-7.svg',
                    tiles: [
                            {ratio: 0.4445, minWidth: '480px', minHeight: '1080px', colspan: 1, rowspan: 2, col: 0, row: 0, title: 'Tile1', adType: 'Image', url: this.placeHolderImage},
                            {ratio: 1.7778, minWidth: '960px', minHeight: '540px', colspan: 2, rowspan: 1, col: 1, row: 0, title: 'Tile2', adType: 'Image', url: this.placeHolderImage},
                            {ratio: 0.4445, minWidth: '480px', minHeight: '1080px', colspan: 1, rowspan: 2, col: 3, row: 0, title: 'Tile4', adType: 'Image', url: this.placeHolderImage},
                            {ratio: 1.7778, minWidth: '960px', minHeight: '540px', colspan: 2, rowspan: 1, col: 1, row: 1, title: 'Tile3', adType: 'Image', url: this.placeHolderImage},
                        ]
            },
            // { id: 12, layoutGroup: 'PROMOTION', mode: 'Landscape', cols: 4,  rows: 4, imageUrl: 'assets/images-demo/layout/potrait-layout-4.svg',
            // tiles: [
            //         {colspan: 1, rowspan: 1, col: 0, row: 0, title: 'Tile1', adType: 'Image', url: this.placeHolderImage},
            //         {colspan: 1, rowspan: 1, col: 1, row: 0, title: 'Tile2', adType: 'Image', url: this.placeHolderImage},
            //         {colspan: 2, rowspan: 4, col: 2, row: 0, title: 'Tile3', adType: 'Image', url: this.placeHolderImage},
            //         {colspan: 1, rowspan: 1, col: 0, row: 1, title: 'Tile4', adType: 'Image', url: this.placeHolderImage},
            //         {colspan: 1, rowspan: 1, col: 1, row: 1, title: 'Tile5', adType: 'Image', url: this.placeHolderImage},
            //         {colspan: 1, rowspan: 1, col: 0, row: 2, title: 'Tile6', adType: 'Image', url: this.placeHolderImage},
            //         {colspan: 1, rowspan: 1, col: 1, row: 2, title: 'Tile7', adType: 'Image', url: this.placeHolderImage},
            //         {colspan: 1, rowspan: 1, col: 0, row: 3, title: 'Tile8', adType: 'Image', url: this.placeHolderImage},
            //         {colspan: 1, rowspan: 1, col: 1, row: 3, title: 'Tile9', adType: 'Image', url: this.placeHolderImage},
            //     ]
            // },

        ];
    }

    getLayoutsByMode(mode): ILayout[] {
        const layoutsBySelectedOrientation = [];

        from(this.getLayouts())
            .pipe(filter((l) => l.mode === mode))
            .subscribe((layout) => {
                layoutsBySelectedOrientation.push(layout);
            });

        return layoutsBySelectedOrientation;
    }

    initCampaign() {
        // tslint:disable-next-line:no-angle-bracket-type-assertion
        this.campaign = <ICampaign> {
            Name: '',
            Orientation: 'Landscape',
            State: 'Published',
            Status: 'Playing',
            Expire : (new Date(Date.now() + this.dayUpdate * 24 * 60 * 60 * 1000)),
            Schedule:  new Date(Date.now()),
            Priority: 'Normal',
            Contents: [],
            DeviceGroups: [],
            AggregateId: Guid.newGuid(),
            SessionId: Guid.newGuid(),
            UserId: Guid.newGuid(),
            RowCount: 0,
            ColumnCount: 0
        };
    }

    setOrientation(mode) {
        this.initCampaign();
        this.campaign.Orientation = mode;
        this.orientationChangedSource.next(mode);
    }

    UpdateCampain(campaign: ICampaign) {
        // debugger;
        // this.campaign = {...campaign }
        this.campaign.Name = campaign.Name;
        this.campaign.State = campaign.State;
        this.campaign.Status = campaign.Status;
        this.campaign.Expire = campaign.Expire;
        this.campaign.AggregateId = campaign.AggregateId;
        this.campaign.IsScheduled = campaign.IsScheduled;
        this.campaign.Orientation = campaign.Orientation;
        this.campaign.Priority = campaign.Priority;
        this.campaign.Schedule = campaign.Schedule;

        this.campaign.RowCount = campaign.RowCount;
        this.campaign.ColumnCount = campaign.ColumnCount;
    }

    // addDefaultLocalUrl(url, index: number) {
      // this.campaignUpdatedSource.next(this.campaign.Contents[index].Resources[index].Local = url);
    // }

    addDeviceToCampaign(device: any) {
        this.campaign.DeviceGroups.push(device);
        // console.log(this.campaign);
    }

    resetCampaignContent() {
        this.campaign.Contents = [];
    }


    addContentToCampaign(content: IContent, rowCount, columnCount) {
        // this.campaign.Contents = []; //let's consider only one campaign at a time
        this.campaign.Contents.push(content);
        // debugger;
        // console.log(content);
        // console.log(this.campaign.Contents);
        this.campaign.RowCount = rowCount;
        this.campaign.ColumnCount = columnCount;

        // content.WidgetInstances.forEach((wi) => {
        //     wi.AggregateId = content.AggregateId;
        // });

        this.campaignUpdatedSource.next(this.campaign);
        
        // add contentselected
        this.contentSelectedSource.next(content);
    }

    removeContentFromCampaign(content) {
        const index: number = this.campaign.Contents.indexOf(content);
        if (index !== -1) {
            this.campaign.Contents.splice(index, 1);
        }

        this.campaignUpdatedSource.next(this.campaign);
        const selectedCampaign = this.campaign.Contents.length > 0 ? this.campaign.Contents[0] : null;
        this.contentSelectedSource.next(selectedCampaign);
    }

    removeWidgetInstanceSelected() {
        console.log(this.widgetInstanceSelectedSource);
    }

    contentItemSelected(content: IContent) {
        // const index: number = this.campaign.Contents.indexOf(content);
        this.content = content;
        this.contentSelectedSource.next(content);
    }
    contentUpdate() {
        // debugger;
        this.content.IsUpdate = true;

        // update campain
        const index: number = this.campaign.Contents.indexOf(this.content);
        this.campaign.Contents[index].IsUpdate = true;
        this.campaignUpdatedSource.next(this.campaign);

        // update content
        this.contentSelectedSource.next(this.content);
    }

    getAllDropListsForLayout() {
        return ['Tile1', 'Tile2', 'Tile3', 'Tile4', 'Tile5', 'Tile6', 'Tile7', 'Tile8', 'Tile9', 'ClassRoom1', 'ClassRoom2', 'ClassRoom3', 'ClassRoom4', 'ClassRoom5', 'ClassRoom6', 'ClassRoom7', 'ClassRoom8',  'ClassRoom9'];
    }

    setWidthHeightSource(area: LayoutArea) {
        this.WidthHeightValue = area;
        this.setWidthHeightScale();
    }
    setWidthHeightScale() {
        
        if (this.campaign.Orientation === 'Landscape') {
            const Width = this.WidthHeightValue.Height / this.WidthHeightDefaultValueH.Height;
            const Height =  this.WidthHeightValue.Width / this.WidthHeightDefaultValueH.Width;
            // debugger;
            this.WidthHeightScale = {Width, Height};
        } else {
            const Width = this.WidthHeightValue.Height / this.WidthHeightDefaultValueV.Height;
            const Height =  this.WidthHeightValue.Width / this.WidthHeightDefaultValueV.Width;
            // debugger;
            this.WidthHeightScale = {Width, Height};
        }
    }

    getTextSize(): number {
        // this.orientationChangedSource.subscribe(value => {
        //     debugger;
        //     console.log(value);
        // });
        
        if (this.campaign.Orientation == 'Landscape'){
            return 10.43;
        } else {
            return 13;
        }
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
