import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { StorageService } from '../shared/services/storage.service';
import { ConfigurationService } from '../shared/services/configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { Compiler } from '@angular/core';
import { IUser } from '../profile/profile.model';
import { ProfileService } from '../profile/profile.service';
import { UserDataService } from '../store/user-data.service';
@Injectable()

export class AuthenticationService {
    private authenticationSource = new Subject<boolean>();
    authenticationChallenge$ = this.authenticationSource.asObservable();
    identityUrl: string;
    user: IUser;

    constructor(private http: HttpClient,
                private storage: StorageService,
                private cookie: CookieService,
                private _compiler: Compiler,
                private profileService: ProfileService,
                private userDataService: UserDataService,
                private configurationService: ConfigurationService) {

            if (this.storage.retrieve('is_authorized')) {
                this.authenticationSource.next(true);
            }

            if (this.configurationService.isReady) {
                this.identityUrl = this.configurationService.serverSettings.identityUrl;
            } else {
                this.configurationService.settingsLoaded$.subscribe( (x) => {
                    this.identityUrl = this.configurationService.serverSettings.identityUrl;
                });
            }
    }

    public get IsAuthorized(): boolean {
        return (this.storage.retrieve('is_authorized'));
    }

    public get UserData(): boolean {
        return (this.storage.retrieve('user_profile'));
    }

    public GetToken() {
        return this.storage.retrieve('access_token');
    }

    public ResetAuthorizationData() {
        this.storage.remove('access_token');
        this.storage.remove('user_profile');
        this.storage.remove('is_authorized');
        this._compiler.clearCache();
        // console.log(this._compiler);
        // this._compiler.clearCache();
        // console.log(this.cookie.get('ACCESS_TOKEN'));
        // this.cookie.deleteAll('/', 'file.samuraiscreen.com');
        // this.cookie.deleteAll('/' , 'network.samuraiscreen.com');
        // this.cookie.deleteAll('/' , 'campaign.samuraiscreen.com');
    }

    public SetAuthorizationData(token: any) {
        if (this.storage.retrieve('access_token')) {
            this.storage.remove('access_token');
        }

        this.storage.store('access_token', token);
        this.storage.store('is_authorized', true);
        // this.cookie.set('sessionId', value);
        this.authenticationSource.next(true);
        /*this.getUserData()
            .subscribe(data => {
                this.UserData = data;
                this.storage.store('user_profile', data);
            },
            error => this.HandleError(error),
            () => {
                console.log(this.UserData);
            });*/
    }

    Autenticate(username: string, password: string, returnUrl: string): Observable<any> {
        // debugger;
        return this.http.post<{token: string}>(this.identityUrl + 'User/LogIn',
             {email: username, password, returnUrl, responseType: 'text'},
             )
        .pipe(
            map((result) => {
                // debugger;
                // this.authenticationSource.next(result);
                this.ResetAuthorizationData();
                this.SetAuthorizationData(result);
                return result;
                //
            },
            catchError( (e) => throwError(e))
            ),
        );
    }

    IsLoggedIn(): Observable<string> {
        return this.http.get(this.identityUrl + 'User/IsLoggedIn',  {
            responseType: 'text'}).pipe(
                map((result) => {
                // console.log(result);
                return result;
                },
                catchError( (e) => throwError(e))
            ),
        );
    }
    async asyncAuthGuardIsLog(): Promise<string>  {
        // tslint:disable-next-line: triple-equals
        let response = await fetch(`${this.identityUrl }User/IsLoggedIn`, { credentials: 'include'});
        let result = await response.text();
        // this.setUserID(result.Id);
        //  debugger
        return result;
    }

    async userIsActive(): Promise<string>{
        let response = await fetch(`${this.identityUrl }queries/isactive`, { credentials: 'include'});
        let result = await response.text();
        // this.setUserID(result.Id);
        //  debugger
        return result;
    }

    Logout() {
        return this.http.get(this.identityUrl + 'User/LogOff',  {
            responseType: 'text'}).pipe(
            map((result) => {
                // debugger;
                this.ResetAuthorizationData();
                this.authenticationSource.next(false);
                return result;
            },
            catchError( (e) => throwError(e))
            ),
        );
    }
    async addUserData() {
        this.user = await this.profileService.getUserDetailAsync();
        this.userDataService.addUserData(this.user);
        // add user permission
        switch (this.user.UserType as any) {
          case 'Watch':
            this.userDataService.getWatchUserPermission();
            break;
          case 'Su':
            this.userDataService.getSuperUserPermission();
            break;
          case 'Management':
            this.userDataService.getManagementUserPermission(this.user.UserPermissions);
            break;
          default:
            this.userDataService.getInisialPermissionData();
        }
      }
}
