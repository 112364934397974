import { Pipe, PipeTransform } from '@angular/core';

// tslint:disable-next-line:pipe-naming
@Pipe({ name: 'keyValueUnsorted' })
export class KeyValuePipe implements PipeTransform {
  transform(value: any, args: string[]): any {
    const keys = [];
    // tslint:disable-next-line:forin
    for (const key in value) {
      keys.push({ key, value: value[key]});
    }
    return keys;
  }
}
