import { Injectable } from '@angular/core';
import { IConfiguration } from '../models/configuration.model';
import { StorageService } from './storage.service';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    serverSettings: IConfiguration;
    private settingsLoadedSource = new Subject();
    settingsLoaded$ = this.settingsLoadedSource.asObservable();
    isReady: boolean = false;

    constructor( private storageService: StorageService) { }
    
    load() {
        // For best practise, we haveto load this configuration from server        
        if (environment.production) {
            this.serverSettings = {
                identityUrl: 'http://user.samuraiscreen.com/', 
                userUrl: 'http://user.samuraiscreen.com/',
                companyUrl: 'http://company.samuraiscreen.com/',
                signalrHubUrl: '',
                contentUrl: 'http://content.samuraiscreen.com/',
                campaignUrl: 'http://campaign.samuraiscreen.com/',
                fileUrl: 'http://file.samuraiscreen.com/',
                notificationUrl: 'http://notification.samuraiscreen.com/',
                networkUrl: 'http://network.samuraiscreen.com/',
                exceptionUrl: 'http://exception.samuraiscreen.com/',
                currencyWidget: 'http://currency.samuraiscreen.com/api/currency/',
                widgetsUrl: 'http://widget.samuraiscreen.com/',
            };
        } else {
            this.serverSettings = {
                identityUrl: 'http://103.217.109.239:5201/',
                userUrl: 'http://103.217.109.239:5201/',
                companyUrl: 'http://103.217.109.239:5203/',
                signalrHubUrl: '',
                contentUrl: 'http://103.217.109.239:5205/',
                campaignUrl: 'http://103.217.109.239:5207/',
                fileUrl: 'http://103.217.109.239:5204/',
                notificationUrl: 'http://notification.samuraiscreen.com/',
                networkUrl: 'http://103.217.109.239:5202/',
                exceptionUrl: 'http://103.217.109.239:5208/',
                widgetsUrl: 'http://103.217.109.239:5209/',
                currencyWidget: 'http://currency.samuraiscreen.com/api/currency/'
            };
        }

        this.isReady = true;
        this.settingsLoadedSource.next();
    }
}
