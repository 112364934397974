import { PipeTransform, Pipe } from '@angular/core';
import { TickerSpeed, TickerDataSource, IDataGroup } from './../../samurai-wigets/widgets-model';

@Pipe({
    // tslint:disable-next-line:pipe-naming
    name: 'tickerDataCheek'
  })
  export class TickerDataCheekPipe implements PipeTransform {
    // tslint:disable-next-line:ban-types
    transform(Id: string, selected: TickerDataSource,  dataGroups: IDataGroup[]): boolean {
        // debugger;
        const findDataGroup = dataGroups.find((dataGroup) => dataGroup.DataSource === selected);
        if (findDataGroup === undefined) {  return false; }
        const findData = findDataGroup.Ids.find((dataId) => dataId === Id );
        if (findData !== undefined) {  return true; }
        return false;
    }
  }
