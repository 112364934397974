import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layout.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { CompanyComponent } from '../company/company.component';
import { AuthenticationGuard } from '../authentication/authentication.guard';
import { RefreshComponent } from '../refresh/refresh.component';

const routes: Routes = [
  {
    path: 'app',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: '/app/dashboard', pathMatch: 'full' },
      { path: 'refresh', component: RefreshComponent },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationGuard]   },
      { path: 'company', loadChildren:  () => import('../company/company.module').then(m => m.CompanyModule)},
      // { path: 'chart', loadChildren: '../charts/charts.module#ChartsModule',  canActivate: [AuthenticationGuard]  },
      // { path: 'ecommerce', loadChildren: '../ecommerce/ecommerce.module#ECommerceModule',  canActivate: [AuthenticationGuard] },
      { path: 'network',  loadChildren: () => import('../network/network.module').then(m => m.NetworkModule), canActivate: [AuthenticationGuard]},
      { path: 'support', loadChildren:  () => import('../support/support.module').then(m => m.SupportModule),  canActivate: [AuthenticationGuard] },
      { path: 'campaign', loadChildren: () => import('../campaign-report/campaign-report.module').then(m => m.CampaignReportModule),  canActivate: [AuthenticationGuard] },
      { path: 'profile', loadChildren:  () => import('../profile/profile.module').then(m => m.ProfileModule),  canActivate: [AuthenticationGuard] },
      { path: 'widgets', loadChildren:  () => import('../samurai-wigets/samuwai-wigets.module').then(m => m.SamuwaiWigetsModule), canActivate: [AuthenticationGuard]},
      // { path: 'form', loadChildren: '../forms/forms.module#MyFormsModule'},
      { path: 'page', loadChildren: () => import('../pages/pages.module').then(m => m.PagesModule),  canActivate: [AuthenticationGuard] },
      // { path: 'pglayout', loadChildren: '../page-layouts/page-layouts.module#PageLayoutsModule',  canActivate: [AuthenticationGuard] },
      // { path: 'table', loadChildren: '../tables/tables.module#MyTablesModule',  canActivate: [AuthenticationGuard] },
     // { path: 'ui', loadChildren: '../ui/ui.module#UIModule', },
    ]
  }
];

export const LayoutRoutingModule = RouterModule.forChild(routes);
