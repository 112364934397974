import { Injectable } from '@angular/core';
import { find, filter } from 'lodash';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ConfigurationService } from '../shared/services/configuration.service';
import { IDevicePerformence, INetworkOverview, INetworkCampaignReport } from '../network/network.model';

@Injectable()
export class DashboardService {
   
    private networkUrl: string = '';
    private campaignUrl: string = '';

    constructor(private http: HttpClient,
                private configurationService: ConfigurationService) {
            if (this.configurationService.isReady) {
                this.networkUrl = this.configurationService.serverSettings.networkUrl;
                this.campaignUrl = this.configurationService.serverSettings.campaignUrl;

            } else {
                this.configurationService.settingsLoaded$.subscribe( (x) => this.networkUrl = this.configurationService.serverSettings.networkUrl);
                this.configurationService.settingsLoaded$.subscribe( (x) => this.campaignUrl = this.configurationService.serverSettings.campaignUrl);
            
            }
    }

  
   
    async getCampaignReports() : Promise<INetworkCampaignReport[]> {
        let response = await fetch(`${this.campaignUrl}Queries/GetCampaignReportForNetwork?NumberOfDays=10`, { credentials: 'include'});
        let overview : INetworkCampaignReport[] = await response.json();
        return overview;
    }



    async getNetworkOverView(): Promise<INetworkOverview> {
        const response = await fetch(`${this.networkUrl}Queries/GetNetworkOverview`, { credentials: 'include'});
        let overview : INetworkOverview = await response.json();
                
        return overview;
   }
  
}
