import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILayoutDetatilsPosition } from '../campaign.model';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.scss']
})
export class DetailsDialogComponent implements OnInit {

  positon  = [
    'EBL TV Model-Landscape',
    'EBL Full Camp 1-Portrait',
    'EBL Tv 03-Landscape',
    'EBL TV Model-Landscape',
  ];
  IsCampainUse: boolean = false;

  constructor(public dialogRef: MatDialogRef<DetailsDialogComponent>,  public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public detailsData: ILayoutDetatilsPosition) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmDone(): void {
      // console.log('confirm Click');
  }

}
