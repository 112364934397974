
import { map } from 'rxjs/operators';
import { Component, OnInit, Input, AfterViewChecked, AfterViewInit } from '@angular/core';
import { MapService } from './map.service';
import { MouseEvent, MapsAPILoader } from '@agm/core';
// import { ClusterStyle, ClusterOptions, MarkerClustererInstance } from '@agm/js-marker-clusterer/services/google-clusterer-types';
import { Marker } from '@agm/core/services/google-maps-types';
import { AgmMarker } from '@agm/core/directives/marker';
// import { ClusterManager } from '@agm/js-marker-clusterer/services/managers/cluster-manager';
// import { AgmMarkerCluster } from '@agm/js-marker-clusterer/directives/marker-cluster';
import { HttpClient } from '@angular/common/http';
import { AgmMarkerCluster, ClusterManager } from '@agm/js-marker-clusterer';
import { ClusterStyle, ClusterOptions } from '@agm/js-marker-clusterer/services/google-clusterer-types';

declare const MarkerClusterer;
declare const google: any;

@Component({
    selector: 'app-google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.scss'],
    providers: [MapService]
})
export class GoogleMapComponent implements OnInit, AfterViewInit {
    @Input('markers') markers: Marker[];
    lat: number = 23.7552;
    lng: number = 90.3731;
    bounce: string = 'BOUNCE';
    mapData: any;
    zoom = 16;
    imagepath = './assets/clustererImages/m1.png';
    iconUrl1 = './assets/clustererImages/device1.svg';
    iconUrl2 = './assets/clustererImages/device2.svg';
    style = this.http.get('./assets/data/mapStyle.json').pipe(map((res) => res));

    devicePoints;
    mapsAPILoader = MapsAPILoader;

    clusterStyles: ClusterStyle[];
    clusterOptions: ClusterOptions;
    agmMarkerCluster: AgmMarkerCluster;
    maptype = '{ style:"dropdown_menu", mapTypeIds:["HYBRID","ROADMAP","SATELLITE","TERRAIN"]}';
    constructor(private mapService: MapService,
                private http: HttpClient,
                private _clusterManager: ClusterManager) {}

    mapClicked($event: MouseEvent) {
        console.log($event.coords);
    }
    iconUrl(con: string) {
        if (con === 'Working') {
            return './assets/clustererImages/device1.svg';
        } else if (con === 'Warning') {
            return './assets/clustererImages/device2.svg';
        } else {
            return './assets/clustererImages/device3.svg';
        }
    }

    markerClicked(event) {
        console.log(event);
    }

    async getMapData() {
        this.devicePoints = await this.mapService.getNetworkDeviceOverView();
        // this.devicePoints = await this.mapService.getNetworkOverView();
       // console.log(this.devicePoints);
    }

    zoomClick(event){
        console.log(event);
    }

    async ngOnInit() {

        await this.getMapData();
        this.clusterStyles = [
            {
                textColor: '#FFFFFF',
                url: 'assets/clustererImages/m1.png',
                height: 50,
                width: 54,
                textSize: 10,
            },
            {
                textColor: '#FFFFFF',
                url: 'assets/clustererImages/m2.png',
                height: 50,
                width: 54,
                textSize: 11
            },
            {
                textColor: '#FFFFFF',
                url: 'assets/clustererImages/m3.png',
                height: 50,
                // anchor: [1, 0],
                // iconAnchor: [500, 0],
                width: 54,
                textSize: 11
            }
        ];

        this.clusterOptions = {
            gridSize: 60,
            minimumClusterSize: 2,
            averageCenter: true,
            zoomOnClick: false
        };

    }
    ngAfterViewInit(){
        // if (google) {
        //     console.log(google);
        // }
    }
}
