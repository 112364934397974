import { Pipe, PipeTransform } from '@angular/core';
import { LayerBound } from '../../campaign/widgets/announce-widget/announce-model';
import * as action from '../../campaign/widgets/announce-widget/announce.action.name';
import { CampaignBuilder } from '../../campaign/builder/campaign.builder';
@Pipe({
  name: 'widthHeightCalculator'
})
export class WidthHeightCalculatorPipe implements PipeTransform {
  constructor(private campaignBuilder: CampaignBuilder) {}
  transform(value: number, ...args: any[]): any {
    var width;
    if (value === LayerBound.WrapContent) {
      // width = 'fit-content';
      width = '';
    } else if (value === LayerBound.MatchParent) {
      width = '100%';
    } else {
      width = `${value * this.campaignBuilder.WidthHeightScale.Width}${action.VIEW_UNIT}`;
    }
    return width;
  }

}