import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';

// Page Layouts
import { PageLayoutFullscreenComponent } from './page-layouts/fullscreen/fullscreen.component';
import { AppPreloadingStrategy } from './app-preloading-strategy';

const AppRoutes: Routes = [
  { path: '', redirectTo: '/app/dashboard', pathMatch: 'full' },
  { path: 'app', component: LayoutComponent },
  { path: 'extra', loadChildren: () => import('./extra-pages/extra-pages.module').then( m => m.ExtraPagesModule)},
  { path: 'page', loadChildren: () => import('./pages/pages.module').then( m => m.PagesModule)},
  { path: 'auth', loadChildren: () => import('./authentication/authentication.module').then( m => m.AuthenticationModule)},
  { path: '', loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule)},
  { path: 'fullscreen', component: PageLayoutFullscreenComponent },
  { path: '**', redirectTo: '/app/dashboard', pathMatch: 'full' },
];

export const AppRoutingModule = RouterModule.forRoot(AppRoutes, {
  preloadingStrategy: AppPreloadingStrategy,
  useHash: true
});
