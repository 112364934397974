import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IActionData } from '../shared-component.model';

@Component({
  selector: 'app-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss']
})
export class ActionDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ActionDialogComponent>,  public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public actionData: IActionData) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmDone(): void {
      // console.log('confirm Click');
  }
}
