
export const EMPLOYEE_TEMPLATE = 'employee';
export const TEST_TEMPLATE = 'test';
export const TEMPLATE_ONE = 'templateone';

// Layer Childs Name
export const TEXT_LAYER = 'textlayer';
export const IMAGE_LAYER = 'imagelayer';
export const VIEW_LAYER = 'viewlayer';
export const LINEAR_LAYER_GROUP = 'linearlayergroup';
export const RELATIVE_LAYER_GROUP = 'relativelayergroup';
export const VIEW_UNIT = 'px ';
