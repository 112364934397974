import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    // tslint:disable-next-line:pipe-naming
    name: 'enumToArray'
  })
  export class EnumToArrayPipe implements PipeTransform {
    // tslint:disable-next-line:ban-types
    transform(data: Object) {
      const keys = Object.keys(data);
      return keys.slice(keys.length / 2);
    }
  }
