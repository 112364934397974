import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {
    private fileUrl: string = '';
    private progress: number = 0;

    constructor(private http: HttpClient,
                private configurationService: ConfigurationService) {
        if (this.configurationService.isReady) {
            this.fileUrl = this.configurationService.serverSettings.fileUrl;
        } else {
            this.configurationService.settingsLoaded$.subscribe(x => this.fileUrl = this.configurationService.serverSettings.fileUrl);
        }

    }
}
