import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  // tslint:disable-next-line: pipe-naming
  name: 'rowHeightPipe'
})
export class RowHeightPipe implements PipeTransform {

  transform(rowCount: number, columnCount: number, Orientation: string, mainScreen: any): string {
    // console.log('pipe Call');
    if ( typeof mainScreen !== ('undefined' || null) ) {
        const value = mainScreen._element.nativeElement.offsetWidth;
        if ( Orientation === 'Portrait') {
            // debugger
            const campainRow = rowCount === 1 ? rowCount * 2  : rowCount;
            const ratioValue = Math.floor((( value * 16) / 9) / campainRow);
            // return `${Math.floor(value * 0.89)}px`;
            return `${ratioValue}px`;
        }
        if ( Orientation === 'Landscape') {
            // return `${Math.floor(value / 3.54)}px`;
            // debugger;
            const campainCol = columnCount === 1 ? columnCount * 2  : 2;
            const ratioValue = Math.floor((( value * 9) / 16) / campainCol);
            return `${ratioValue}px`;
        }
    }
}

}
