import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../shared/services/configuration.service';
import { IFile, IGetFileFilter, IFileRequest, IPasschange, IBusinessHourupdate, IGetFiles, IUser, ICompany, ICreateCompany, ICreateUser, IGetUsers, IMofifyUserPermission } from './profile.model';
import { catchError } from 'rxjs/operators';
import { throwError, Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class ProfileService {

    private userUrl: string = '';
    private companyUrl: string = '';
    private fileUrl: string = '';

    private userId = new BehaviorSubject<string>(null);
    userId$ = this.userId.asObservable();

    constructor(private http: HttpClient,
                private configurationService: ConfigurationService) {
        if (this.configurationService.isReady) {
            this.userUrl = this.configurationService.serverSettings.userUrl;
            this.companyUrl = this.configurationService.serverSettings.companyUrl;
            this.fileUrl = this.configurationService.serverSettings.fileUrl;
        } else {
            this.configurationService.settingsLoaded$.subscribe( (x) => {
                this.userUrl = this.configurationService.serverSettings.userUrl;
                this.companyUrl = this.configurationService.serverSettings.companyUrl;
                this.fileUrl = this.configurationService.serverSettings.fileUrl;
            });
        }
    }

    setUserID(Id: string) {
        this.userId.next(Id);
        // debugger;
    }
    // getCompany() {
    //     return [
    //         {
    //             id: '1',
    //             name: 'Tiger IT Bangladesh',
    //             address: 'Dhanmondi',
    //             phone: '123045647',
    //             email: 'example@gmail.com',
    //             website: 'www.testsite.com',
    //             country: 'Bangladesh'
    //         }, {
    //             id: '2',
    //             name: 'Magnito Digital',
    //             address: 'Mothizhel',
    //             phone: '123045647',
    //             email: 'example@gmail.com',
    //             website: 'www.testsite.com',
    //             country: 'Dhaka',
    //         }, {
    //             id: '3',
    //             name: 'NewCred',
    //             address: 'Mirpur',
    //             phone: '123045647',
    //             email: 'example@gmail.com',
    //             website: 'www.testsite.com',
    //             country: 'Dhaka',
    //         },
    //         {
    //             id: '4',
    //             name: 'Southtech Limited',
    //             address: 'Khilgoan',
    //             phone: '123045647',
    //             email: 'example@gmail.com',
    //             website: 'www.testsite.com',
    //             country: 'Dhaka',
    //         },
    //         {
    //             id: '5',
    //             name: 'Data Soft',
    //             address: 'Sukhrabad',
    //             phone: '123045647',
    //             email: 'example@gmail.com',
    //             website: 'www.testsite.com',
    //             country: 'Dhaka',
    //         }
    //     ];
    // }

    async getUserDetailAsync(): Promise<IUser> {
        let response = await fetch(`${this.userUrl}Queries/GetUser`, { credentials: 'include'});
        let result = await response.json();
        // this.setUserID(result.Id);
        return await result;
    }

    getAllUsers(sort: string, pageIndex: number, pageSize: number): Observable<IGetUsers> {
        // debugger;
        return this.http.get<IGetUsers>(
            this.userUrl + `queries/getallusers?PageIndex=${pageIndex}&PageSize=${pageSize}`)
            .pipe(
                catchError((e) => throwError(e))
            );
    }

    async getUserPermission(userId: string): Promise<any> {
        // debugger;
        let response = await fetch(`${this.userUrl}Queries/GetUserPermissions?id=${userId}`, { credentials: 'include'});
        let result = await response.json();
        return result;
    }

    async getCompanyDetailAsync(companyId: string): Promise<ICompany> {
        let response = await fetch(`${this.companyUrl}Queries/GetCompany?companyId=${companyId}`, { credentials: 'include'});
        let result = await response.json();
        return await result;
    }

    async modifyUserPermission(modify: any): Promise<any> {
        // debugger;        
        // return this.http.post( this.userUrl + `User/ModifyPermissions`,  modify)
        //     .pipe(
        //         catchError((e) => throwError(e))
        //     );
        const response = await fetch(this.userUrl + `User/ModifyPermissions`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(modify),
        });
    }

    async getFilesAsync(filter: IGetFileFilter): Promise<IGetFiles> {

        let response = await fetch(`${this.fileUrl}api/queries/getfiles?Type=${filter.Type}&PageIndex=${filter.PageIndex}&PageSize=${filter.PageSize}`, { credentials: 'include'});       
        let result: IGetFiles = await response.json();
        return result;
    }
    getFileObserb(filter: IGetFileFilter): Observable<IGetFiles> {

        return this.http.get<IGetFiles>(
            this.fileUrl + `api/queries/getfiles?Type=${filter.Type}&PageIndex=${filter.PageIndex}&PageSize=${filter.PageSize}`)
            .pipe(
                catchError((e) => throwError(e))
            );
    }

    DeleteFileAsync(file: IFileRequest) {
        return this.http.post(
            this.fileUrl + `api/Commands/DeleteFile`, file)
            .pipe(
                catchError((e) => throwError(e))
            );
        // let response = await fetch(`${this.fileUrl}api/Commands/DeleteFile`, { method: 'POST', body: JSON.stringify(file), credentials: 'include'});
        // let result = await response.json();
        // return result.Results;
    }

    changePassword(pasdata: IPasschange): Observable<any> {
        const url = this.userUrl + `user/changepassword`;
        // debugger;
        return this.http.post(
            this.userUrl + `user/changepassword`, pasdata)
            .pipe(
                catchError((e) => throwError(e))
            );
    }

    async IsPassMatchAsync(password: string): Promise<boolean> {
        let response = await fetch(`${this.userUrl}queries/PasswordMatched?password=${password}`, { credentials: 'include'});
        let result = await response.json();
        return await result;
    }


    addCompany(company: ICreateCompany): Observable<any> {
        return this.http.post<ICreateCompany>(this.companyUrl + 'Commands/CreateCompany/' , company, {
            reportProgress: true,
          })
        .pipe(
            catchError( (e) => throwError(e))
        );
    }

    addUser(user: ICreateUser): Observable<any> {
        // debugger;
        return this.http.post<ICreateUser>(this.userUrl + 'User/SignUp/' , user, {
            reportProgress: true,
          })
        .pipe(
            catchError( (e) => throwError(e))
        );
    }

    
    async changeBusinessHour(data: IBusinessHourupdate) {
        const response = await fetch(this.companyUrl + `Commands/ChangeBusinessHour`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(data),
        });
       
    }
}
