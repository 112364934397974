import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { CHARTCONFIG } from '../charts/charts.config';
import { DashboardService } from './dashboard.service';
import { INetworkOverview, DeviceStatus } from '../network/network.model';
import { Router, NavigationStart } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SubSink } from 'SubSink/dist/subsink';

@Component({
    selector: 'my-dashboard',
    templateUrl: './dashboard.component.html',
    providers: [DashboardService, DatePipe]
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

    config = CHARTCONFIG;
    shoulShow = true;

    test = 'Test';
    networkOverview: INetworkOverview;
    DeviceStatus = DeviceStatus;
    screenTimes: number[] = [];
    impressions: number[] = [];
    dates: string[] = [];
    trafficChart: any;
    trafficChartVh: any;
    isReportsCall: boolean = true;
    showSpinner: boolean = true;
    private subs = new SubSink();

    constructor(private dashboardService: DashboardService,
                private router: Router,
                private datePipe: DatePipe, ) {}

    ngOnInit(): void {
        this.getNetworkOverview();
        this.getCampaingReports();
        this.onResize();
    }

    ngAfterViewInit() {
      this.doTask();
    }

    doTask() {
      this.router.events.subscribe(e => {
        if (e instanceof NavigationStart) {
          // console.log(e);
          if (e.url !== '/app/dashboard'){
            this.isReportsCall = false;
            // console.log('stop task');
          }
        }
      });
      // if (this.isReportsCall) {
      //   setTimeout( () => {
      //     // infinity call chart data
      //     this.getCampaingReports();
      //     this.screenTimes = [];
      //     this.impressions = [];
      //     this.dates = [];
      //     this.doTask();
      //   }, 10000);
      // }
    }

    onResize() {
      // debugger;
      let vh = window.innerHeight * 0.1;
      // debugger;
      // Then we set the value in the --vh custom property to the root of the document
      this.trafficChartVh = `61`;
    }
    deviceStatClicked(filter: DeviceStatus) {
        // debugger;
        if (filter == null) {
            this.router.navigate(['app/network/device/list']);
        } else {
            if (filter == DeviceStatus.Working) {
                if (this.networkOverview.TotalDevice == this.networkOverview.WorkingDevice) {
                    this.router.navigate(['app/network/device/list']);
                } else {
                    this.router.navigate(['app/network/device/list', DeviceStatus[filter]])
                }

            } else if (filter == DeviceStatus.Warning) {
                if (this.networkOverview.DevicewithWarning !== 0) {
                    this.router.navigate(['app/network/device/list', DeviceStatus[filter]])
                }
            } else if (filter == DeviceStatus.Dead) {
                if (this.networkOverview.DeadDevice !== 0) {
                    this.router.navigate(['app/network/device/list', DeviceStatus[filter]])
                }
            }
        }
    }

    async getCampaingReports() {
        this.showSpinner = true;
        var result = await this.dashboardService.getCampaignReports();
        this.showSpinner = false;
        result.forEach( (element) => {
            // this.screenTimes.push(element.ScreenTime !== 0  ? element.ScreenTime : 4 );
            // this.impressions.push(element.Impression !== 0  ? element.Impression : 6 );
            this.screenTimes.unshift(element.ScreenTime / 60);
            this.impressions.unshift(element.Impression);
            var local = this.datePipe.transform(element.Date, 'MMM d');
            this.dates.unshift(local);
        });
        // debugger;
        this.trafficChart = {
          title : {
            text: 'All Campaign Report',
            subtext: 'Data show till last 10 days',
            x: 'center',
            textStyle: {
              color: this.config.black
            },
          },
          toolbox: {
            show : true,
            x: 'right',
            y: 'top',
            feature : {
              saveAsImage : {show: true, title: 'save'}
            }
          },
          legend: {
            show: true,
            x: 'left',
            y: 'top',
            textStyle: {
              color: this.config.textColor
            },
            data: ['Impression', 'ScreenTime']
          },
          grid: {
            x: 40,
            y: 60,
            x2: 40,
            y2: 30,
            borderWidth: 0
          },
          tooltip: {
            show: true,
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                color: this.config.gray
              }
            }
          },
          xAxis: [
            {
              type : 'category',
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                textStyle: {
                  color: this.config.textColor
                }
              },
              splitLine: {
                show: false,
                lineStyle: {
                  color: this.config.splitLineColor
                }
              },
              data : this.dates
            }
          ],
          yAxis: [
            {
              type : 'value',
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                textStyle: {
                  color: this.config.textColor,
                  fontSize: 10
                }
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: this.config.splitLineColor
                }
              }
            }
          ],
          series: [
          
            {
              name: 'Impression',
              type: 'bar',
              clickable: false,
              itemStyle: {
                normal: {
                  color: this.config.success, // '#8BC34A', // Light Green 500
                  barBorderRadius: 0
                },
                emphasis: {
                  // color: this.config.success
                }
              },
              barCategoryGap: '60%',
              data: this.impressions,
              legendHoverLink: false,
              z: 2
            },
            {
              name: 'ScreenTime',
              type: 'bar',
              smooth: true,
              itemStyle: {
                normal: {
                  color: this.config.primary, // '#03A9F4', // Light Blue 500
                  barBorderRadius: 0
                },
                emphasis: {
                  // color: this.config.primary
                }
              },
              barCategoryGap: '60%',
              data: this.screenTimes,
              symbol: 'none',
              legendHoverLink: false,
              z: 2
            }
          
          ]
        };
    }
    getMonData = () => {
        const data = [];
        for (let i = 0; i < 13; i++) {
            data.push('Mon. ' + i);
        }
        return data;
    }

    monData = this.getMonData();

    async getNetworkOverview() {
        this.showSpinner = true;
        this.networkOverview = await this.dashboardService.getNetworkOverView();
        this.showSpinner = false;
    }
    ngOnDestroy() {
      this.subs.unsubscribe();
    }
}
