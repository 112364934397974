import { Pipe, PipeTransform } from '@angular/core';
import { CampaignBuilder } from '../../campaign/builder/campaign.builder';

@Pipe({
  name: 'scaleRatio'
})
export class ScaleRatioPipe implements PipeTransform {
  constructor(private campaignBuilder: CampaignBuilder) {}
  transform(value: any, ...args: any[]): any {

    // debugger;
    // console.log(this.campaignBuilder.WidthHeightScale);
    // Math.round(value * this.campaignBuilder.WidthHeightScale.Width);;
    return (value * this.campaignBuilder.WidthHeightScale.Width);
  }

}
