import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EChartsDirective } from './directives/echarts.directive';
import { SlimScrollDirective } from './directives/slim-scroll.directive';
import { ContenteditableDirective } from './directives/contenteditable.directive';

// Services
import { DataService } from './services/data.service';
import { SecurityService } from './services/security.service';
import { ConfigurationService } from './services/configuration.service';
import { StorageService } from './services/storage.service';
import { FileService } from './services/file.service';
import { PipeModule } from './pipe/pipe.module';
import { MaterialModule } from './metarial.module';
import { SharedComponentModule } from './component/shared-component.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    SharedComponentModule,
    ReactiveFormsModule,
    RouterModule,
    PipeModule
  ],
  declarations: [
    EChartsDirective,
    SlimScrollDirective,
    ContenteditableDirective,
    // UppercasePipe,

  ],
  exports: [
    SharedComponentModule,
    MaterialModule,
    EChartsDirective,
    SlimScrollDirective,
    ContenteditableDirective,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipeModule,
    RouterModule,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
      return {
          ngModule: SharedModule,
          providers: [
              // Providers
              DataService,
              SecurityService,
              ConfigurationService,
              StorageService,
              FileService
          ]
      };
  }
}
