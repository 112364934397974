
import { throwError as observableThrowError,  BehaviorSubject ,  Observable } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import { SecurityService } from './security.service';
import { Guid } from '../models/guid';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

// Implementing a Retry-Circuit breaker policy
// is pending to do for the SPA app
@Injectable()
export class DataService {
    constructor(private http: HttpClient, private securityService: SecurityService) { }

    get(url: string, params?: any): Observable<HttpResponse<any>> {
        const options: any = {};

        if (this.securityService) {
            options.headers = new HttpHeaders();
            options.headers.append('Authorization', 'Bearer ' + this.securityService.GetToken());
        }
       
        return this.http.get(url, options).pipe(map(
            (res: any) => {
            return res;
        }), catchError(this.handleError),);
    }

    postWithId(url: string, data: any, params?: any): Observable<any> {
        return this.doPost(url, data, true, params);
    }

    post(url: string, data: any, params?: any): Observable<any> {
        return this.doPost(url, data, false, params);
    }

    putWithId(url: string, data: any, params?: any): Observable<any> {
        return this.doPut(url, data, true, params);
    }

    private doPost(url: string, data: any, needId: boolean, params?: any): Observable<any> {
        const options: any = {};

        options.headers = new HttpHeaders();
        if (this.securityService) {
            options.headers.append('Authorization', 'Bearer ' + this.securityService.GetToken());
        }
        if (needId) {
            const guid = Guid.newGuid();
            options.headers.append('x-requestid', guid);
        }

        return this.http.post(url, data, options).pipe(map(
            (res: any) => {
                return res;
            }), catchError(this.handleError), );
    }

    private doPut(url: string, data: any, needId: boolean, params?: any): Observable<HttpResponse<any>> {
        const options: any = {};

        options.headers = new HttpHeaders();
        if (this.securityService) {
            options.headers.append('Authorization', 'Bearer ' + this.securityService.GetToken());
        }
        if (needId) {
            const guid = Guid.newGuid();
            options.headers.append('x-requestid', guid);
        }

        return this.http.put(url, data, options).pipe(map(
            (res: any) => {
                return res;
            }), catchError(this.handleError),);
    }

    delete(url: string, params?: any) {
        const options: any = {};

        if (this.securityService) {
            options.headers = new HttpHeaders();
            options.headers.append('Authorization', 'Bearer ' + this.securityService.GetToken());
        }

        console.log('data.service deleting');
        // return this.http.delete(url, options).subscribe(
        //        return res;
        //    );

        this.http.delete(url, options).subscribe((res) => {
            console.log('deleted');
        });
    }

    private handleError(error: any) {
        console.error('server error:', error);
        if (error) {
            let errMessage = {};
            try {
                errMessage = error.json();
            } catch (err) {
                errMessage = error.statusText;
            }
            return observableThrowError(errMessage);
        }
        return observableThrowError(error || 'server error');
    }

    // Observable number source
    private messageSource = new BehaviorSubject<number>(2);
    // Observable number stream
    dataNumber$ = this.messageSource.asObservable();

    changeMessage(message: number) {
        this.messageSource.next(message);
        console.log(this.dataNumber$);
    }
}
