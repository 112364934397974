export interface ICurrencyData {
    Id?: string;
    CompanyId?: string;
    Flag: string;
    Name: string;
    Buy: number;
    BuyTrend: Trend;
    Sell: number;
    SellTrend: Trend;
    CreateDate?: Date;
}

export enum Trend {
    up,
    down,
    flat
}

export enum ScrollAmount {
    Slow = 1,
    Normal = 3,
    Fast = 10
}
export interface ITickerStyle {
    Background: ITickerBackground;
    Font: ITickerFont;
    ScrollAmount: string;
}


export interface ITickerListData {
    Id: string;
    Name: string;
    IsShow: boolean;
}
export interface ICounter {
    AggregateId: string;
    TokenNo: string;
    CompanyId?: string;
    CounterNo: string;
    EastimateTime?: string;
    RepresentativeName: string;
    CreateDate?: Date;
}

export interface IDeposit {
    Id?: string;
    CompanyId?: string;
    Name: string;
    Rate: string;
    CreateDate?: Date;
    IsActive?: boolean;
}
export interface ILoan {
    Id?: string;
    CompanyId?: string;
    Name: string;
    Rate: string;
    IsActive?: boolean;
    CreateDate?: Date;
}
export interface INews {
    AggregateId: string;
    CompanyId: string;
    Title: string;
    Description: string;
    CreateDate?: Date;
    UpdateDate?: Date;
    IsActive?: boolean;
}

export interface ICurrencyLocal {
    ShortName: string;
    FullName: string;
    CurrencyName: string;
    CurrencyShort: string;
}
export interface ITicker {
    Id?: string;
    Data: string;
    CreateDate: string;
}
export interface ITickerControllerData {
    Stauts: TickerStatus;
    Speed: TickerSpeed;
    DataGroups: IDataGroup[];
    Background: ITickerBackground;
    Font: ITickerFont;
    Id: string;
    CompanyId: string;
}
export interface IDataGroup {
    DataSource: TickerDataSource;
    Ids: string[];
    viewId?: IViewId[];
}
export interface IViewId {
    Id: string;
    IsShow: boolean;
}
export enum TickerStatus {
    Playing,
    Paused
}
export interface IwidgetListData {
    Currency: ITickerListData[];
    Loan: ITickerListData[];
    Deposit: ITickerListData[];
    Self: ITickerListData[];
}
export enum TickerSpeed {
    Slow,
    Medium,
    Fast
}
export enum TickerDataSource {
    Currency,
    Loan,
    Deposit,
    Self
}
export interface ITickerBackground {
    Color: string;
    Opacity: number;
}
export interface ITickerFont {
    Color: string;
    FontSize: number;
    Opacity?: number;
    PaddingTop: number;
    PaddingBottom: number;
}

export interface IJsonTickets {
    status: string;
    tickets: any;
    Tickers: ITicker[];
}