import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    // tslint:disable-next-line:pipe-naming
    name: 'formatAmPm'
  })
  export class FormatAmPm implements PipeTransform {
    // tslint:disable-next-line:ban-types
    transform(time: string) {
        let timeValue = time.split(':');
        let hours = parseInt(timeValue[0], 10);
        let minutes = parseInt(timeValue[1], 10);
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        let  Newminutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + Newminutes + ' ' + ampm;
        return strTime;
    }
  }
