import { Component, OnInit, DoCheck, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../../shared/services/data.service';
import { SecurityService } from '../../../shared/services/security.service';
import { UserDataService } from '../../../store/user-data.service';

@Component({
  selector: 'my-app-sidenav-menu',
  styles: [],
  templateUrl: './sidenav-menu.component.html',
  providers: [DataService, SecurityService]
})

export class AppSidenavMenuComponent implements OnInit, OnChanges {
  currentUrl: string;
  length;
  isSuperUser: boolean = false;
  constructor(private router: Router, private dataService: DataService,
              private userDataService: UserDataService) {
    router.events.subscribe((_: NavigationEnd) => this.currentUrl = _.url);
  }

  ngOnInit() {
    // this.length = 4;
    // this.networkService.currentMessage.subscribe((message) => this.length = message);
    this.userDataService.userUpdated$.subscribe((user: any) => {
      if(user) {
        const type  = user.UserType as any;
        this.isSuperUser = (type === 'Su' ? true : false);
      }
    });
    this.dataService.dataNumber$.subscribe((message) => this.length = message);
  }
  ngOnChanges(changes: SimpleChanges) {
    const name: SimpleChange = changes.length.currentValue;
    this.length = name;
  }
}
