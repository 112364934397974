import { PipeTransform, Pipe } from '@angular/core';
import { TickerSpeed } from './../../samurai-wigets/widgets-model';

@Pipe({
    // tslint:disable-next-line:pipe-naming
    name: 'tickerScrollAmount'
  })
  export class TickerScrollAmountPipe implements PipeTransform {
    // tslint:disable-next-line:ban-types
    transform(data: TickerSpeed) {
        // console.log(data);
        switch (data) {
            case TickerSpeed.Fast:
                return 10;
            case TickerSpeed.Medium:
                return 6;
            case TickerSpeed.Slow:
                return 3;
          }
    }
  }
