import { NgModule, ApplicationRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// Layout
import { LayoutComponent } from './layout/layout.component';
import { PreloaderDirective } from './layout/preloader.directive';
// Header
import { AppHeaderComponent } from './layout/header/header.component';
// Sidenav
import { AppSidenavComponent } from './layout/sidenav/sidenav.component';
import { ToggleOffcanvasNavDirective } from './layout/sidenav/toggle-offcanvas-nav.directive';
import { AutoCloseMobileNavDirective } from './layout/sidenav/auto-close-mobile-nav.directive';
import { AppSidenavMenuComponent } from './layout/sidenav/sidenav-menu/sidenav-menu.component';
import { AccordionNavDirective } from './layout/sidenav/sidenav-menu/accordion-nav.directive';
import { AppendSubmenuIconDirective } from './layout/sidenav/sidenav-menu/append-submenu-icon.directive';
import { HighlightActiveItemsDirective } from './layout/sidenav/sidenav-menu/highlight-active-items.directive';
// Customizer
import { AppCustomizerComponent } from './layout/customizer/customizer.component';
import { ToggleQuickviewDirective } from './layout/customizer/toggle-quickview.directive';
// Footer
import { AppFooterComponent } from './layout/footer/footer.component';
// Search Overaly
import { AppSearchOverlayComponent } from './layout/search-overlay/search-overlay.component';
import { SearchOverlayDirective } from './layout/search-overlay/search-overlay.directive';
import { OpenSearchOverlaylDirective } from './layout/search-overlay/open-search-overlay.directive';

// Pages
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageLayoutFullscreenComponent } from './page-layouts/fullscreen/fullscreen.component';

import { ProfileService } from './profile/profile.service';

// Sub modules
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';

// hmr
import { removeNgStyles, createNewHosts } from '@angularclass/hmr';

import { JwtModule } from '@auth0/angular-jwt';

import { ConfigurationService } from './shared/services/configuration.service';
import { AuthenticationService } from './authentication/authentication.service';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { StorageService } from './shared/services/storage.service';
import { CookieService } from 'ngx-cookie-service';
import { JwtInterceptor } from './shared/inspectors/token.httpinterceptor';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmJsMarkerClustererModule, ClusterManager } from '@agm/js-marker-clusterer';
import { GoogleMapComponent } from './google-map/google-map.component';
// import { ClusterManager } from '@agm/js-marker-clusterer/services/managers/cluster-manager';
import { CacheInterceptor } from './shared/inspectors/cache.interceptos';
import { AppPreloadingStrategy } from './app-preloading-strategy';
import { DeleteDialogComponent } from './campaign/delete-dialog/delete-dialog.component';
import { DetailsDialogComponent } from './campaign/details-dialog/details-dialog.component';
import { RefreshComponent } from './refresh/refresh.component';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
   // SafePipeModule,
   // DragDropModule,
   // ColorPickerModule,
    // Sub modules
    SharedModule,
    AppRoutingModule,
    LayoutModule,
    NgxMaterialTimepickerModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCIXho4QDdWiZ5i-FhuySspgrbkQse5EnM'
    }),
    AgmJsMarkerClustererModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        // whitelistedDomains: ['company.reviewflakes.com', 'review.reviewflakes.com'],
        // blacklistedRoutes: ['localhost:4200/api/auth']
      }
    })
  ],
  declarations: [
    AppComponent,
    // Layout
    LayoutComponent,
    PreloaderDirective,
    // Header
    AppHeaderComponent,
    // Sidenav
    AppSidenavComponent,
    ToggleOffcanvasNavDirective,
    AutoCloseMobileNavDirective,
    AppSidenavMenuComponent,
    AccordionNavDirective,
    AppendSubmenuIconDirective,
    HighlightActiveItemsDirective,
    // Customizer
    AppCustomizerComponent,
    ToggleQuickviewDirective,
    
    // Footer
    AppFooterComponent,

    // Search overlay
    AppSearchOverlayComponent,
    SearchOverlayDirective,
    OpenSearchOverlaylDirective,
    //
    DashboardComponent,
    // Pages
    PageLayoutFullscreenComponent,
    GoogleMapComponent,
    DetailsDialogComponent,
    DeleteDialogComponent,
    // LoadingSpinnerComponent,
    RefreshComponent,
  ],
  entryComponents: [
    DetailsDialogComponent,
    DeleteDialogComponent
  ],
  exports: [
    DetailsDialogComponent,
    DeleteDialogComponent,
    // LoadingSpinnerComponent
  ],
  providers: [
    // 3rd party service
    ClusterManager,
    GoogleMapsAPIWrapper,

    // custom services
    ConfigurationService,
    StorageService,
    CookieService,
    AuthenticationService,
    AuthenticationGuard,
    AppPreloadingStrategy,
    // CampaignService,
    // WidgetService,
    // WidgetEvents,
    ProfileService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    // provider used to create fake backend
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(public appRef: ApplicationRef) {}
  hmrOnInit(store) {
    console.log('HMR store', store);
  }
  hmrOnDestroy(store) {
    const cmpLocation = this.appRef.components.map((cmp) => cmp.location.nativeElement);
    // recreate elements
    store.disposeOldHosts = createNewHosts(cmpLocation);
    // remove styles
    removeNgStyles();
  }
  hmrAfterDestroy(store) {
    // display new elements
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
}
