import * as jQuery from 'jquery';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription }   from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { APPCONFIG } from './config';
import { LayoutService } from './layout/layout.service';
import { AuthenticationService } from './authentication/authentication.service';
import { ConfigurationService } from './shared/services/configuration.service';
import { CookieService } from 'ngx-cookie-service';
// 3rd
import 'styles/material2-theme.scss';
import 'styles/bootstrap.scss';
// custom
import 'styles/layout.scss';
import 'styles/theme.scss';
import 'styles/ui.scss';
import 'styles/app.scss';
import { UserDataService } from './store/user-data.service';
import { ProfileService } from './profile/profile.service';
import { IUser, UserType } from './profile/profile.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [LayoutService],
})
export class AppComponent implements OnInit {
  public AppConfig: any;
  Authenticated: boolean = false;
  subscription: Subscription;
  user$: Subscription;
  userPermission$: Subscription;
  user: IUser;

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private configurationService: ConfigurationService,
              private userDataService: UserDataService,
              public http: HttpClient) {
      this.Authenticated = this.authenticationService.IsAuthorized;
    }

  async ngOnInit() {

    this.AppConfig = APPCONFIG;
    this.subscription = this.authenticationService.authenticationChallenge$.subscribe(res => this.Authenticated = res);

    // Scroll to top on route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.configurationService.load();
    
    this.ping();

    // user permission add
    this.authenticationService.addUserData();

    // user Is Active
    try {
      const userisActive = await this.authenticationService.userIsActive();
      this.userDataService.changeUserActiveStatus(Boolean(userisActive));
    } catch (e) {
      console.error(e);
    }
  }

  ping() {
    this.http
      .get(this.configurationService.serverSettings.identityUrl + 'User/IsLoggedIn', {
      responseType: 'text'})
      .subscribe((data) => console.log(data), (err) => console.log(err));
  }
}
