import { NgModule } from '@angular/core';
import { ActionDialogComponent } from './action-dialog/action-dialog.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './metarial.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [
    ActionDialogComponent
  ],
  exports: [
    ActionDialogComponent
  ],
  // entryComponents: [
  //   ActionDialogComponent
  // ]
})

export class SharedComponentModule {}
