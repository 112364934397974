import { Injectable } from '@angular/core';
import { IUser, UserPermission } from '../profile/profile.model';
import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { IUserPermission, IUserPermissionData } from './user.model';

@Injectable({
        providedIn: 'root'
    })

export class UserDataService {
    constructor() {
        this.getInisialPermissionData();
    }

    userIsActive = new BehaviorSubject<boolean>(false);
    userIsActiveSelected$ = this.userIsActive.asObservable();

    public userPermission: IUserPermission;
    public permissionEditor: IUserPermissionData[] = [
        {
            Id: 0,
            Name: 'EditCampaign',
            Details: 'Campaign Edit',
            Action: false
        },
        {
            Id: 1,
            Name: 'PublishCampaign',
            Details: 'Publish Campaign',
            Action: false
        },
        {
            Id: 2,
            Name: 'UploadFiles',
            Details: 'Upload Files',
            Action: false
        },
        {
            Id: 3,
            Name: 'CanEditDevice',
            Details: 'Device Edit',
            Action: false
        },
        {
            Id: 4,
            Name: 'CanEditSubstation',
            Details: 'Substation Edit',
            Action: false
        },
        {
            Id: 5,
            Name: 'CanEditGroups',
            Details: 'Groups Edit',
            Action: false
        },
        {
            Id: 6,
            Name: 'CanAddGroups',
            Details: 'Groups Add',
            Action: false
        },
        {
            Id: 7,
            Name: 'CanRemoveCampaign',
            Details: 'Remove Campaign',
            Action: false
        },
    ];

    private _user = new BehaviorSubject<IUser>(null);
    userUpdated$ = this._user.asObservable();

    private _permissionEditor = new BehaviorSubject<IUserPermissionData[]>(this.permissionEditor);
    permissionEditorUpdate$ = this._permissionEditor.asObservable();

    private _userPermission = new BehaviorSubject<IUserPermission>(
        {
            EditCampaign: false,
            PublishCampaign: false,
            UploadFiles: false,
            CanEditDevice: false,
            CanEditSubstation: false,
            CanEditGroups: false,
            CanAddGroups: false,
            CanRemoveCampaign: false,
        }
    );
    userPermissionUpdated$ = this._userPermission.asObservable();

    getInisialPermissionData() {
        const inisialPermission = {
            EditCampaign: false,
            PublishCampaign: false,
            UploadFiles: false,
            CanEditDevice: false,
            CanEditSubstation: false,
            CanEditGroups: false,
            CanAddGroups: false,
            CanRemoveCampaign: false,
        }

        this.userPermission = inisialPermission;
        this._userPermission.next(this.userPermission);
    }
    getWatchUserPermission() {
        this.getInisialPermissionData();
    }

    getSuperUserPermission() {
        const superPermission = {
            EditCampaign: true,
            PublishCampaign: true,
            UploadFiles: true,
            CanEditDevice: true,
            CanEditSubstation: true,
            CanEditGroups: true,
            CanAddGroups: true,
            CanRemoveCampaign: true,
        };
        this.userPermission = superPermission;
        this._userPermission.next(this.userPermission);

    }
    addUserData(user: IUser) {
        this._user.next(user);
    }

    getManagementUserPermission(manageName: string[]) {
        manageName.forEach((name) => this.userPermission[name] = true);
        this._userPermission.next(this.userPermission);
        // debugger;
        // this.userPermission.next({...this.userPermission, })
        // debugger;
        // const keys = Object.keys(this.userPermission);
        // keys.forEach((key) => {
        //     if (key === manageName){
        //         debugger;
        //         // 
        //     }
        // })
        
    }

    changeUserActiveStatus(isActive: boolean) {
        this.userIsActive.next(isActive);
    }
}
