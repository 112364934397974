import { NgModule } from '@angular/core';
import { KeyValuePipe } from './object.pipe';
import { EnumToArrayPipe } from './enumToArray.pipe';
import { FormatAmPm } from './formatAmPm.pipe';
import { RowHeightPipe } from './row-height.pipe';
import { ConvertHexPipe } from './convert-hex.pipe';
import { TickerDataCheekPipe } from './ticker-data.cheek.pipe';
import { TickerScrollAmountPipe } from './ticker-scroll-amount.pipe';
import { WidthHeightCalculatorPipe } from './width-height-calculator.pipe';
import { ScaleRatioPipe } from './scale-ratio.pipe';
import { ContentAlignPipe } from './content-align.pipe';
import { ClipPathPipe } from './clip-path.pipe';

@NgModule({
  imports: [
  ],
  declarations: [
    KeyValuePipe,
    EnumToArrayPipe,
    FormatAmPm,
    RowHeightPipe,
    ConvertHexPipe,
    TickerDataCheekPipe,
    TickerScrollAmountPipe,
    WidthHeightCalculatorPipe,
    ScaleRatioPipe,
    ContentAlignPipe,
    ClipPathPipe
  ],
  exports: [
    KeyValuePipe,
    EnumToArrayPipe,
    FormatAmPm,
    RowHeightPipe,
    ConvertHexPipe,
    TickerDataCheekPipe,
    TickerScrollAmountPipe,
    WidthHeightCalculatorPipe,
    ScaleRatioPipe,
    ContentAlignPipe,
    ClipPathPipe
  ]
})

export class PipeModule {}
