import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private auth: AuthenticationService) {}

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // const currentUser = this.auth.authenticationChallenge$.subscribe((message) => console.log(message));

        // await this.auth.asyncAuthGuardIsLog() == "True"
        // localStorage.getItem('access_token')
        // console.log(await this.auth.asyncAuthGuardIsLog());
        if (localStorage.getItem('access_token')) {
            return true;
        }

        this.router.navigate(['/auth/login'], {
            queryParams: {
                returnUrl: state.url
            }
        });

        // return false;
        // if (!this.auth.isAuthenticated()) {
        //     this.router.navigate(['login']);
        //     return false;
        //   }
        // return true;
    }

    canActivateChild() {
        // console.log('checking child route access');
        return true;
    }
}
