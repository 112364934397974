import { Injectable } from '@angular/core';
import { find, filter } from 'lodash';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ConfigurationService } from '../shared/services/configuration.service';

@Injectable()
export class MapService {
    private networkUrl: string = '';
    private campaignUrl: string = '';
    private mapData = './assets/data/mapData.json';

    constructor(private http: HttpClient,
                private configurationService: ConfigurationService  ) {
        if (this.configurationService.isReady) {
            this.networkUrl = this.configurationService.serverSettings.networkUrl;
            this.campaignUrl = this.configurationService.serverSettings.campaignUrl;

        } else {
            this.configurationService.settingsLoaded$.subscribe( (x) => this.networkUrl = this.configurationService.serverSettings.networkUrl);
            this.configurationService.settingsLoaded$.subscribe( (x) => this.campaignUrl = this.configurationService.serverSettings.campaignUrl);
        }
    }

    async getNetworkOverView(): Promise<any> {
        let response = await fetch(this.mapData);
        let overview = await response.json();
        return overview.Locations;
    }
    getMapData(): Observable<any> {
        return this.http.get<any>(this.mapData)
        .pipe(
            catchError((e) => throwError(e))
        );
    }

    async getNetworkDeviceOverView(): Promise<any> {
        const response = await fetch(`${this.networkUrl}Queries/GetNetworkDevicesOverview`, { credentials: 'include'});
        var res  = await response.json();
        return res;
    }
}
