import { Component, OnInit } from '@angular/core';
import { APPCONFIG } from '../../config';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../authentication/authentication.service';

@Component({
    selector: 'my-app-header',
    styles: [],
    templateUrl: './header.component.html'
})

export class AppHeaderComponent implements OnInit {
    public AppConfig: any;
    isLoggedIn: any;

    constructor(private auth: AuthenticationService, private router: Router) {}

    ngOnInit() {
        this.AppConfig = APPCONFIG;
        this.IsLoggedIn();
    }

    IsLoggedIn() {
        this.auth.IsLoggedIn().subscribe(
            (value) => {
                this.isLoggedIn = value;
                // console.log(value);
            },
            (err: any) => {
                this.isLoggedIn = false;
                // this.router.navigate(['extra/maintenance']);
                console.error(err);
            });
    }

    logout() {

        this.auth.Logout().subscribe(
            (value) => {
                this.isLoggedIn = value;
            },
            (err: any) => {
                this.isLoggedIn = true;
        });
        // location.reload(true);
        this.router.navigate(['auth/login']);
        // tslint:disable-next-line:only-arrow-functions
        // this.router.routeReuseStrategy.shouldReuseRoute = () => {
        //     return false;
        //   };
    }
}
