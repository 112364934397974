import { Pipe, PipeTransform } from '@angular/core';
import { LayerOrientation, Alignment } from '../../campaign/widgets/announce-widget/announce-model';

@Pipe({
  name: 'contentAlign'
})
export class ContentAlignPipe implements PipeTransform {
  alignment = Alignment;
  transform(alignment: Alignment): any {
    const data = (this.alignment[alignment] == 'Center') ? 'center' : 'flex-' + this.alignment[alignment];
    return data;
  }

}
