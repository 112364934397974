export interface IEmployee {
    EmployeeData: IEmployeeData;
    EmployeeStyle: IEmployeeStyle;
}
export interface IEmployeeData {
    Avator: string;
    Address: string;
    Name: string;
    Description: string;
    Company: string;
    Position: string;
}
export interface IEmployeeStyle {
    ZoneOneBarkGround: string;
    ZoneTwoBarkGround: string;
    NameColor: string;
    DetatisColor: string;
    CompanyNameColor: string;
    PositionColor: string;

}

// backend data

export enum Alignment {
    Start,
    Center,
    End
}
export enum LayerOrientation {
    Vetical,
    Horizontal
}
export enum LayerBound {
    MatchParent = -1,
    WrapContent = -2,
}
export interface ITextLayerStyle {
    FontColor: string;
    FontFamily: string;
    FontSize: number;
    TextAlignment: Alignment;
    LineHeight?: number;
    LineHeightW?: number;
    IsEditFont?: boolean;
    IsCapital: boolean;
}

export interface ILayerPosition {
    Height: number;
    Width: number;
    WidthW?: any;
    HeightW?: any;
    WidthT?: any;
    HeightT?: any;
    BottomW?: any;
    RightW?: any;
    HorizontalAlignment?: Alignment;
    VerticalAlignment?: Alignment;
    AlignSelf?: any;
    AlignItems?: any;
    IsAlignItem?: boolean;
    Ratio?: number;
    RatioW?: any;
    Padding?: ILayerMargin;

}
export interface IShadow {
    Elevation: number;
    Color?: string;
}

export interface ICornerRadius {
    TopLeft: number;
    TopRight: number;
    BottomLeft: number;
    BottomRight: number;
}
export interface ILayerMargin {
        Top: number;
        Bottom: number;
        Left: number;
        Right: number;
}

export enum MPName {
    LayerMargin,
    Layerpadding
}

export interface ILayerElementBorder {
        Thickness?: number;
        //CornerRadius: ICornerRadius;
        Color?: string;
}
// export interface ILayerMP {
//      MP: ILayerMargin;
//      Name: MPName;
// }
export interface ILayerElement {
    Background?: IBackground;
    LayerPosition?: ILayerPosition;
    PositionW?: any;
    Shadow?: IShadow;
    ZIndex?: number;
    IsEdit?: boolean;
    // CornerRadius?: ICornerRadius;
    LayerMargin?: ILayerMargin;
    LayerPadding?: ILayerMargin;
    Name: string;
    Rotation?: number;
    Border?: ILayerElementBorder;
    CornerRadius?: ICornerRadius;
    LayerOrientation?: LayerOrientation;
}

export interface ITextLayer extends ILayerElement {
    Text: string;
    Style: ITextLayerStyle;

}
export interface IImageLayer extends ILayerElement {
    Source: string;
    IsChange?: boolean;
    IsRatio?: boolean;
    IsRound?: boolean;
    Ratio?: string;
    RatioW?: any;
    RatioH?: any;
    Url?: any;
    CornerRadius?: ICornerRadius;
}
export interface IBackground {
    Opacity: number;
    Color?: string;
    Data?: string;
    Data100?: string;
    Transform?: number;
    ShapeType?: ShapeType;
    IsGradient?: boolean;
    StartColor?: string;
    EndColor?: string;
}
enum ShapeType { Polygon, Path }

// export interface IBackground {
//     Opacity?: number;
//     IsGradient?: boolean;
// }

export interface ISolidBackground extends IBackground {
    Color: string;
}
export interface ILinearGradientBackground extends IBackground {
    StartColor: string;
    EndColor: string;
    GradientOrientation: GradientOrientation;
}

export enum GradientOrientation {
        BlTr,
        BottomTop,
        BrTl,
        LeftRight,
        RightLeft,
        TlBr,
        TopBottom,
        TrBl,
}
export interface ILayerGroup extends ILayerElement {
    Childs: ILayerElement[];
    LayerOrientation: LayerOrientation;
}
export interface IRelativeLayerGroup extends ILayerGroup {
    //
}

export interface ILinearLayerGroup extends ILayerGroup {
    LayerOrientation: LayerOrientation;
}

export interface ITemplate {
    LayerOrientation: LayerOrientation;
    LayerGroups: ILayerGroup[];
}
