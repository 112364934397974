export interface IDeviceGroupPageResponse {
    PageSize: number;
    PageIndex: number;
    Skipped: number;
    TotalCount: number;
    Results: IDeviceGroup[];
}

export interface IActionData {
    ActionName: string;
    NetworkName: string;
}

export interface ISubstationPageResponse{
    PageSize: 10,
    PageIndex: 0,
    Skipped: 0,
    TotalCount: 0,
    Results: ISubstation[]
}

export interface IDevicePageResponse {
    PageSize: number;
    PageIndex: number;
    Skipped: number;
    TotalCount: number;
    Results: IDevice[];
}
export interface ISubstationAdd {
    AggregateId: string;
    Name: string;
    Address: string;
    Ip: string;
    Lon: number;
    Lat: number;
    City: string;
}

export interface ISubstationUpdate {
    AggregateId: string;
    Name: string;
    Address: string;
    Ip: string;
    Lon: number;
    Lat: number;
    City: string;
    ImageUri: string;

}

export enum DeviceStatus {
    Working,
    Warning,
    Dead,
}

export interface IDeviceGroup {
    Id: string;
    Name: string;
    Description: string;
    CompanyId: string;
}

export enum Orientation {
    Portrait,
    Landscape
}

export enum DeviceTypes {
       Fsu,
       Wmu,
       Vwu
   }
export interface IDeviceAdd {
    AggregateId: string;
    Name: string;
    Address: string;
    Ip: string;
    MacAddress: string;
    Type: DeviceTypes;
    SubstationId: string;
    Orientation: Orientation;
    Lat: number;
    Lon: number;
}

export interface IDeviceUpdate {
    AggregateId: string;
    Name: string;
    Ip: string;
    MacAddress: string;
    Type: DeviceTypes;
    Orientation: Orientation;
    ImageUri: string;
    Lat: number;
    Lon: number;
    SubstationId: string;

}


export interface IDevicePerformence {
    DeviceId: string;
    Time: Date;
    CpuUsage: string;
    StorageFree: string;
    MemoryUsage: string;
    Temperature: string;
}

export interface INetworkOverview
{
    TotalDevice : Number;
    DeadDevice : Number;
    DevicewithWarning : Number;
    WorkingDevice : Number;
}

export interface INetworkCampaignReport 
{
    Impression : number;
    ScreenTime : number;
    Date : Date ;
}

export interface PageResponse {
    PageSize:10,
    PageIndex:0,
    Skipped:0,
    TotalCount:0
}

export interface ISubstation {
    Id: string;
    Name: string;
    CompanyId: string;
    ImageUri: string;
    Address: string;
    Ip: string;
    Lon: string;
    Lat: string;
    City: string;
    Traffic: number;

}

export interface IDevice {
    Id: string;
    Name: string;
    Audience: number;
    CompanyId: string;
    ImageUri: string;
    SubstationId: string;
    SubstationName: string;
    Ip: string;
    MacAddress: string;
    Orientation: string;
    Lat: number;
    Lon: number;
    Type: DeviceTypes;
    Status: DeviceStatus;
    SystemInfo: IDeviceSystemInfo;
    Groups: Groups[];
}

export interface IDeviceSystemInfo
{
    Model: string;
    Version: string;
    SupportedAbis: string[];
    Serial: string;
    BuildId: string;
    UseableRam: string;
    UseableStorage: string;
}

export interface Groups {
    Id: string;
    Name: string;
}

export interface IStatData {
    Time: string;
    Data: string;
}

export interface IDeviceStatistic {
   
    Date: Date;
    Data: IStatData[];
   
}

export interface IUpdateDeviceFile{
    IsFileChanged: boolean;
    Event: any;
    FileType: any;
}
