import { Pipe, PipeTransform } from '@angular/core';
// import { test } from './../../../assets/data/clipPath.svg';
@Pipe({
  name: 'clipPath'
})
export class ClipPathPipe implements PipeTransform {

  transform(shape: number, data: any): any {
    const svg =  `
    <svg height="0" width="0">
      <defs>
        <clipPath id="svgPath">
            <path fill="#FFFFFF" stroke="#000000" stroke-width="1.5794" stroke-miterlimit="10" changeIt/>
        </clipPath>
      </defs>
    </svg>
    `;
    const  path = `d="${data}"`;

    if (shape === 0) {
      const data1 = `1190px,1080px 0px,1080px 160.8px,0px 1190px,0px`;
      const data2 = `6% 1%, 100% 0, 100% 100%, 0% 100%`;

      return `polygon(${data1})`;
    } else {
      const value = svg.replace('changeIt', path);
      const parser = new DOMParser();
      const doc = parser.parseFromString(value, 'image/svg+xml');
      console.log(doc);
      const url = './assets/data/clipPath.svg';
      debugger;
      return `url(${url})`;
    }
  }

}
